import { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { deleteVehicleById, getAllVehicles } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import AddVehicle from "./add-vehicle";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { CarImage, Dot, Desc, Cell } from "src/components/Common/SimpleComponents";
import ApiUtils from '../../helpers/utils';
import moment from 'moment';
import { exportToCSV } from "src/helpers/file_helper";


const Vehicles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { vehicles, loading, confirm_del, success } = useSelector(
    (state: any) => state.vehicle
  );
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [vehicleList, setVehiclesList] = useState<any>([]);
  const [currentVehicle, setCurrentVehicle] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const hasPermission = currentUser?.permissions?.vehicles;

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      setIsAdmin(true);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(getAllVehicles());
  }, []);

  useEffect(() => {
    setVehiclesList(vehicles);
  }, [vehicles]);

  const onEdit = (vehicle: any) => {
    setCurrentVehicle(vehicle);
    history.push(
      `/vehicles/${vehicle.id}`
    );
  };

  const onDelete = (vehicle: any) => {
    setCurrentVehicle(vehicle);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteVehicleById(currentVehicle.id));
    if (success) {
      setShowSuccessAlert(true)
    }
  };

  const onAdd = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  const onExport = () => {

    const vehicleData = vehicleList.map((vehicle: any) => {
      return {
        "RegNo": vehicle.regNo,
        "CompanyCode": vehicle.companyCode,
        "CustomerName": vehicle.userInfo?.userName,
        "CustomerPhone": vehicle.userInfo?.phone,
        "LatestValue": vehicle.valuationInfo?.TradeValuation,
        "Mileage": vehicle.mileage,
        "WarrantyDue": vehicle.warrantyDue ? moment(vehicle.warrantyDue).format("DD/MM/YYYY") : "",
        "ServiceDue": vehicle.serviceDue ? moment(vehicle.serviceDue).format("DD/MM/YYYY") : "",
        "MotExpiryDate": vehicle.smartInfo?.MotExpiryDate ? moment(vehicle.smartInfo?.MotExpiryDate).format("DD/MM/YYYY") : "",
        "InsuranceDue": vehicle.insuranceDue ? moment(vehicle.insuranceDue).format("DD/MM/YYYY") : "",
        "TaxDueDate": vehicle.smartInfo?.TaxDueDate ? moment(vehicle.smartInfo?.TaxDueDate).format("DD/MM/YYYY") : "",
      }
    });

    exportToCSV(vehicleData, "vehicles", []);
  }

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Vehicles")}
      data={vehicleList}
      onAdd={onAdd}
      onExport={isAdmin ? onExport : undefined}
      hasPermission={hasPermission}
      title={t("vehicles.title")}
      description={`(${vehicles.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={vehicles.length}
      defaultSorted={defaultSorted}
      footer={
        <AddVehicle open={drawer} onDrawerClose={onDrawerClose} />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Vehicles);

const header = [
  {
    dataField: "image",
    text: "Image",
    formatter: (_: any, vehicle: any) => (
      <CarImage src={vehicle?.image} alt="" />
    ),
  },
  {
    dataField: "regNo",
    text: "Reg No",
    sort: true,
  },
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true
  },
  {
    dataField: "customerName",
    text: "Customer Name",
    formatter: (_: any, vehicle: any) =>
      vehicle?.userInfo?.userName,
    sort: true
  },
  {
    dataField: "phone",
    text: "Customer Phone",
    formatter: (_: any, vehicle: any) =>
      vehicle?.userInfo?.phone,
    sort: true,
  },
  {
    dataField: "latestValue",
    text: "Latest Value",
    formatter: (_: any, vehicle: any) =>
      vehicle?.valuationInfo?.TradeValuation,
    sort: true,
  },
  {
    dataField: "mileage",
    text: "Mileage",
    sort: true,
  },
  {
    dataField: "warrantyDue",
    text: "Warranty",
    formatter: (_: any, vehicle: any) => (
      vehicle.warrantyDue ? <Cell>
        <Dot isExpired={ApiUtils.isExpired(vehicle.warrantyDue)} />
        <Desc>{moment(vehicle.warrantyDue).format("DD MMM, YYYY")}</Desc>
      </Cell> : <Cell>-</Cell>
    ),
  },
  {
    dataField: "isHendy",
    text: "IsHendy",
    sort: true,
  },
  {
    dataField: "serviceDue",
    text: "Service",
    formatter: (_: any, vehicle: any) => (
      vehicle.serviceDue ? <Cell>
        <Dot isExpired={ApiUtils.isExpired(vehicle.serviceDue)} />
        <Desc>{moment(vehicle.serviceDue).format("DD MMM, YYYY")}</Desc>
      </Cell> : <Cell>-</Cell>
    ),
  },
  {
    dataField: "smartInfo.MotExpiryDate",
    text: "Mot",
    formatter: (_: any, vehicle: any) => (
      vehicle.smartInfo?.MotExpiryDate ? <Cell>
        <Dot isExpired={ApiUtils.isExpired(vehicle.smartInfo.MotExpiryDate)} />
        <Desc>{moment(vehicle.smartInfo.MotExpiryDate).format("DD MMM, YYYY")}</Desc>
      </Cell> : <Cell>-</Cell>
    ),
  },
  {
    dataField: "insuranceDue",
    text: "Insurance",
    formatter: (_: any, vehicle: any) => (
      vehicle.insuranceDue ? <Cell>
        <Dot isExpired={ApiUtils.isExpired(vehicle.insuranceDue)} />
        <Desc>{moment(vehicle.insuranceDue).format("DD MMM, YYYY")}</Desc>
      </Cell> : <Cell>-</Cell>
    ),
  },
  {
    dataField: "smartInfo.TaxDueDate",
    text: "Tax",
    formatter: (_: any, vehicle: any) => (
      vehicle.smartInfo?.TaxDueDate ? <Cell>
        <Dot isExpired={ApiUtils.isExpired(vehicle.smartInfo.TaxDueDate)} />
        <Desc>{moment(vehicle.smartInfo.TaxDueDate).format("DD MMM, YYYY")}</Desc>
      </Cell> : <Cell>-</Cell>
    ),
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
