import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import useCustomers from 'src/hooks/useCustomers';
import { ICustomer } from 'src/interfaces/general';
import styled from 'styled-components';


interface IProps {
    onSelect: (value: ICustomer[]) => void;
    filter: any;
}

const Customer = ({
    checked: checkedProp,
    customer,
    onSelect
}: {
    checked: boolean,
    customer: ICustomer,
    onSelect: (checked: boolean) => void
}) => {
    const [checked, setChecked] = React.useState<boolean>(false);

    const onClick = () => {
        setChecked(!checked);
        onSelect(!checked);
    }

    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    return (
        <div className='item-container' key={customer.id} onClick={onClick}>
            <input
                type="checkbox"
                className="form-check-input"
                id="customCheck1"
                checked={checked}
                onChange={() => { }}
            />
            <div className='name'>{customer.phone}</div>
            <div className='phone'>{customer.userName}</div>
        </div>
    )
}

const SelectCustomer = (props: IProps, ref: any) => {

    const user = useSelector((state: any) => state.login.currentUser);
    const { loading, customers, fetchFilteredCustomers } = useCustomers();
    const [filterText, setFilterText] = React.useState('');
    const [selected, setSelected] = React.useState<ICustomer[]>([]);
    const [selectAll, setSelectAll] = React.useState<boolean>(false);
    const companyCodeRef = React.useRef<string>(user?.companyCode);
    const filteredCustomers = customers.filter((customer: any) => customer.appInstalled === true);

    const onSearch = () => {
    }

    const onSelect = (customer: ICustomer, checked: boolean) => {

        if (checked) {
            setSelected([...selected, customer]);
        } else {
            setSelected(selected.filter((s: ICustomer) => s.id !== customer.id));
        }
    }

    const onSelectAll = () => {
        if (selectAll) {
            setSelected([]);
        } else {
            setSelected(filteredCustomers);
        }
        setSelectAll(!selectAll);
    }

    React.useEffect(() => {
        props.onSelect(selected);
        if (selected.length === filteredCustomers.length && selected.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selected]);

    useImperativeHandle(ref, () => ({
        fetch: () => {
            fetchFilteredCustomers({
                companyCode: user?.code || user?.companyCode,
                ...props.filter
            });
        }
    }));

    useEffect(() => {
        companyCodeRef.current = user?.code || user?.companyCode;
    }, [user]);

    return (
        <Container>
            <div className='search-header'>
                <h6>{filteredCustomers.length} Filtered App Users</h6>
                <div className="form-group mb-4">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={filterText}
                            onChange={(e: any) => setFilterText(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={onSearch}
                            >
                                <i className="mdi mdi-magnify" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <div>Loading...</div>}
            {filteredCustomers
                .filter((customer: any) => (
                    customer.userName?.toLowerCase().includes(filterText.toLowerCase()) ||
                    customer.phone?.toLowerCase().includes(filterText.toLowerCase())
                ))
                .map((customer: any) => (
                    <Customer
                        key={customer.id}
                        checked={selected.some((s: ICustomer) => s.id === customer.id)}
                        customer={customer}
                        onSelect={(value) => onSelect(customer, value)}
                    />
                ))
            }
            {/* Select All */}
            <div className='item-container footer' onClick={() => onSelectAll()}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                    checked={selectAll}
                    onChange={() => { }}
                />
                <div className='name'>Select All</div>
            </div>
        </Container>
    )

}

export default forwardRef(SelectCustomer);

const Container = styled.div`
    padding: 0 20px;
    max-height: 500px;
    overflow: scroll;

    .search-header {
        position: sticky;
        top: 0;
        background: #fff;
        padding-top: 10px;
        z-index: 1;
    }

    .item-container {
        border: 1px solid #ccc;
        padding: 10px;
        margin: 5px 0;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &:hover {
            background: #ccc;
        }

        .name {
            font-weight: bold;
            font-size: 14px;
            margin-left: 10px;
        }

        .phone {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    .footer {
        position: sticky;
        bottom: 0;
        background: #fff;
        z-index: 1;
    }
`