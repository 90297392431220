import { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import useNotifications from "src/hooks/useNotifications";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { Tag } from "src/components/Common/SimpleComponents";
import { trim } from "../../helpers/general";
import { INotification } from "src/interfaces/general";

const Notification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<any>([]);
  const { notifications, loading, deleteNotification } = useNotifications(currentUser?.code || currentUser?.companyCode);

  const hasPermission = currentUser?.permissions?.notifications;

  const onDelete = (notification: any) => {
    setCurrentNotification(notification);
    setShowConfirmAlert(true);
  };

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteNotification(currentNotification?.id || "");
    if (result) {
      setShowSuccessAlert(true);
    }
  };

  const onAdd = () => {
    history.push(`/notifications/add`);
  }

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Notifications")}
      data={notifications}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("Notifications")}
      description={`(${notifications.length})`}
      columns={makeColumns(onDelete, null, header)}
      pageSize={10}
      totalSize={notifications.length}

      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Notification);

const header = [
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (_: any, notification: INotification) => {
      let borderColor = "grey";
      let color = "lightGrey";
      switch (notification.status) {
        case "failed":
          borderColor = "red";
          color = "lightPink";
          break;
        case "discarded":
          borderColor = "grey";
          color = "lightGrey";
          break;
        case "pending":
          borderColor = "blue";
          color = "lightBlue";
          break;
        case "sent":
          borderColor = "green";
          color = "lightGreen";
          break;
        case "retry":
          borderColor = "orange";
          color = "yellow";
          break
        default:
          borderColor = "grey";
          color = "lightGrey";
      }

      let message = notification.status;

      if (notification.statusMessage) {
        message += ` - ${notification.statusMessage}`;
      }

      return (
        <div style={{ textAlign: "center" }} title={message}>
          <span style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: color,
            borderColor: borderColor,
            borderWidth: "2px",
            borderStyle: "solid",
          }}></span>
        </div>
      );
    },
  },
  {
    dataField: "phone",
    text: "Customer Phone",
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "regNo",
    text: "Reg No",
    sort: true,
  },
  {
    dataField: "body",
    text: "Body",
    sort: true,
    formatter: (_: any, Notification: INotification) => (
      <span>{trim(Notification.body, 30)}</span>
    ),
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
    formatter: (_: any, Notification: INotification) => (
      <span>{trim(Notification.subject, 20)}</span>
    ),
  },
  {
    dataField: "createdAt",
    text: "Created",
    formatter: (_: any, Notification: any) => (
      moment(Notification.createdAt).format("DD/MM/YYYY")
    ),
  },
  {
    dataField: "sentAt",
    text: "Sent",
    formatter: (_: any, Notification: any) => (
      Notification?.sentAt ? moment(Notification.sentAt).format("DD/MM/YYYY") : "N/A"
    ),
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_: any, role: any) => (

      (role?.type) ? <Tag type="normal"><span>{role?.type?.toUpperCase() ?? ""}</span></Tag> : <span></span>
    ),
  },
  {
    dataField: "unread",
    text: "Unread",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];